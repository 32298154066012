<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol
                cols="6"
              >
                <h1 class="mb-3 tt-text-headline-1">
                  Настройки
                </h1>
              </VCol>
            </VRow>
            <VForm
              v-if="showForm"
              ref="form"
              v-model="valid"
            >
              <VRow>
                <VCol cols="12">
                  <h2 class="tt-text-body-2 mb-3">
                    Вход в личный кабинет
                  </h2>
                  <TTCheckbox
                    v-model="entity.two_factor_auth"
                    hide-details="auto"
                    data-test-label="config-two-factor-checkbox"
                    class="mt-0 pt-0"
                    :ripple="false"
                    label="Двухфакторная авторизация в личный кабинет"
                  />
                </VCol>
                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <TTTextField
                    :value="entity.sms_text"
                    large
                    label="Приветственное сообщение"
                    placeholder="Введите текст сообщения"
                    :messages="['Это сообщение получат новые сотрудники в SMS с приглашением в приложение.']"
                    :error="validation('sms_text').status"
                    :error-messages="validation('sms_text').value"
                    data-test="config-sms-text-input"
                    @input="v => handleChange('sms_text',v)"
                  />
                </VCol>

                <VCol
                  cols="12"
                >
                  <VDivider class="mt-3 mb-6" />
                  <h3 class="tt-text-subtitle">
                    Встречи
                  </h3>
                </VCol>

                <VCol cols="6">
                  <TTSelect
                    :value="entity.checkpoint.provider"
                    large
                    label="Интеграция встреч"
                    :items="checkpoints"
                    :error="validation('checkpoint.provider').status"
                    :error-messages="validation('checkpoint.provider').value"
                    data-test="config-checkpoint-provider-select"
                    @input="v => handleCheckpointChange('provider',v, 'checkpoint.provider')"
                  />
                </VCol>
                <VCol cols="12">
                  <TTCheckbox
                    v-model="entity.checkpoint.is_active"
                    hide-details="auto"
                    data-test-label="config-checkpoint-is-actice-checkbox"
                    class="mt-0 pt-0"
                    :ripple="false"
                    label="Включить напоминание о встрече через приложение"
                  />
                </VCol>
                <VCol cols="6">
                  <h3 class="tt-text-body-2 mb-3">
                    Время по умолчанию 9:00 (можно менять)
                  </h3>
                  <CtxTimePicker
                    :disabled="!entity.checkpoint.is_active"
                    :value="entity.checkpoint.default_time || '09:00'"
                    filled
                    append-icon="fal fa-clock"
                    hide-details="auto"
                    single-line
                    content-class="pt-0"
                    data-test="config-checkpoint-default-time"
                    @input="v => handleCheckpointChange('default_time',v)"
                  />
                </VCol>

                <VCol
                  cols="12"
                >
                  <VDivider class="mt-3 mb-6" />
                  <h3 class="tt-text-subtitle">
                    Настройки приложения
                  </h3>
                </VCol>

                <VCol
                  cols="12"
                  class="pb-0"
                >
                  <TTCheckbox
                    v-model="entity.request_email"
                    hide-details="auto"
                    class="mt-0 pt-0"
                    data-test-label="config-request-email-checkbox"
                    :ripple="false"
                    label="Запрашивать email через приложение"
                  />
                  <TTCheckbox
                    v-model="entity.show_purposes"
                    hide-details="auto"
                    class="pt-0"
                    data-test-label="config-show-purposes-checkbox"
                    :ripple="false"
                    label="Отображать блок «Цели на испытательный срок»"
                  />
                  <TTCheckbox
                    v-model="entity.use_tutorship"
                    hide-details="auto"
                    class="pt-0"
                    data-test-label="config-show-purposes-checkbox"
                    :ripple="false"
                    label="Отображать блок «Наставничество»"
                  />
                  <TTCheckbox
                    v-model="showRating"
                    hide-details="auto"
                    class="pt-0"
                    data-test-label="config-show-purposes-checkbox"
                    :ripple="false"
                    label="Отображать вкладку «Рейтинг» на пребординге"
                  />
                </VCol>

                <VCol cols="12">
                  <VDivider class="mt-3 mb-6" />
                  <h3 class="tt-text-subtitle">
                    Бот
                  </h3>
                </VCol>

                <VCol cols="6">
                  <h3 class="tt-text-body-2 mb-3">
                    Загрузите иконку бота
                  </h3>
                  <UploadAvatar
                    :value="entity.bot_icon_url"
                    mandatory
                    data-test="config-upload-avatar"
                    @input="v => handleChange('bot_icon_base64',v)"
                  />
                </VCol>
                <VCol cols="6">
                  <TTTextField
                    :value="entity.bot_name"
                    large
                    label="Имя бота"
                    placeholder="Введите имя бота"
                    :error="validation('bot_name').status"
                    :error-messages="validation('bot_name').value"
                    data-test="config-bot-name-input"
                    @input="v => handleChange('bot_name',v)"
                  />
                </VCol>

                <VCol cols="12">
                  <VDivider class="mt-3 mb-6" />
                  <h3 class="tt-text-subtitle">
                    Цветовая схема
                  </h3>
                  <h4 class="tt-text-body-2 tt-light-mono-46--text mt-2">
                    Указывайте шестизначное значение цветов.
                  </h4>
                </VCol>

                <VCol cols="6">
                  <h3 class="tt-text-body-2 mb-3">
                    Основной цвет
                  </h3>
                  <div class="d-flex align-start">
                    <div
                      class="colored-box mr-4"
                      :style="`background-color: ${entity.style.primary}`"
                    />
                    <div
                      style="width: 224px"
                      class="mr-4"
                    >
                      <SColorField
                        :value="entity.style.primary"
                        hide-details="auto"
                        placeholder="Введите цвет"
                        :error="validation('style.primary').status"
                        :error-messages="validation('style.primary').value"
                        data-test="config-style-primary-color"
                        @input="v => handleStyleChange('primary',v)"
                      />
                    </div>
                    <TTBtn
                      v-if="entity.style.primary !== '#0025FF'"
                      color="tt-secondary"
                      large
                      square
                      data-test="config-undo-button-primary-color"
                      @click="setDefaultColor('primary', '#0025FF')"
                    >
                      <VIcon size="19">
                        fal fa-undo
                      </VIcon>
                    </TTBtn>
                  </div>
                  <h3 class="tt-text-body-2 mb-3 mt-6">
                    Цвет текста на кнопках
                  </h3>
                  <div class="d-flex align-start">
                    <div
                      class="colored-box mr-4"
                      :style="`background-color: ${entity.style.primaryText}`"
                    />
                    <div
                      style="width: 224px"
                      class="mr-4"
                    >
                      <SColorField
                        :value="entity.style.primaryText"
                        hide-details="auto"
                        placeholder="Введите цвет"
                        :error="validation('style.primaryText').status"
                        :error-messages="validation('style.primaryText').value"
                        data-test="config-style-primary-text-color"
                        @input="v => handleStyleChange('primaryText',v)"
                      />
                    </div>
                    <TTBtn
                      v-if="entity.style.primaryText !== '#FFFFFF'"
                      color="tt-secondary"
                      data-test="config-undo-button-primary-text-color"
                      square
                      large
                      @click="setDefaultColor('primaryText', '#FFFFFF')"
                    >
                      <VIcon size="19">
                        fal fa-undo
                      </VIcon>
                    </TTBtn>
                  </div>
                  <VRow>
                    <VCol
                      v-if="validPrimaryColor"
                    >
                      <h3 class="tt-text-body-2 mt-6 mb-3">
                        Цвет при нажатии
                      </h3>
                      <div class="d-flex align-center justify-start">
                        <div
                          class="colored-box mr-4"
                          :style="`background-color: ${lightenColor}`"
                        />
                        <VRadioGroup
                          v-model="colorRadios"
                          hide-details
                          class="pt-0 mt-0"
                          data-test="config-style-radio-group"
                        >
                          <VRadio
                            label="Светлая палитра"
                            value="light"
                            color="tt-black"
                            :ripple="false"
                            data-test="config-style-radio-light"
                          />
                        </VRadioGroup>
                      </div>
                      <div class="d-flex align-center justify-start mt-6">
                        <div
                          class="colored-box mr-4"
                          :style="`background-color: ${darkenColor}`"
                        />
                        <VRadioGroup
                          v-model="colorRadios"
                          hide-details
                          class="pt-0 mt-0"
                        >
                          <VRadio
                            label="Темная палитра"
                            value="dark"
                            color="tt-black"
                            data-test="config-style-radio-dark"
                            :ripple="false"
                          />
                        </VRadioGroup>
                      </div>
                    </VCol>
                  </VRow>
                </VCol>

                <VCol
                  cols="6"
                  data-test="config-style-example"
                  class="pb-1"
                >
                  <VCard
                    width="376"
                    class="pa-6"
                    :style="`box-shadow: 0px 2px 24px rgba(11, 18, 24, 0.08);border-radius: 12px;`"
                  >
                    <h3 class="tt-text-body-2 mb-4">
                      Пример кнопок
                    </h3>
                    <VCardText
                      :style="`background-color: ${entity.style.primary}; border: 1px solid ${entity.style.primary}`"
                      class="text-center colored-box-example mb-5"
                    >
                      <h3
                        :style="`color: ${entity.style.primaryText};font-size: 14;font-weight: 500`"
                      >
                        Пример использования
                      </h3>
                    </VCardText>
                    <VCardText
                      :style="`background-color: ${entity.style.primaryText};border: 1px solid ${entity.style.primary}`"
                      class="text-center colored-box-example"
                    >
                      <h3
                        :style="`color: ${entity.style.primary};font-size: 14;font-weight: 500`"
                      >
                        Пример использования
                      </h3>
                    </VCardText>
                  </VCard>
                </VCol>

                <VCol cols="12">
                  <VDivider class="mt-3 mb-6" />
                  <h3 class="tt-text-subtitle">
                    Бонус
                  </h3>
                  <h4 class="tt-text-body-2 tt-light-mono-46--text mt-2">
                    Просклоняйте название бонусных баллов в зависимости от количества.
                  </h4>
                </VCol>

                <VCol cols="6">
                  <TTTextField
                    :value="entity.phrase.bonus.one"
                    large
                    label="Один"
                    placeholder="Введите название"
                    :error="validation('phrase.bonus.one').status"
                    :error-messages="validation('phrase.bonus.one').value"
                    data-test="config-phrase-bonus-one-input"
                    @input="v => handleBonusChange('one', v, 'phrase.bonus.one')"
                  />
                </VCol>
                <VCol cols="6">
                  <TTTextField
                    :value="entity.phrase.bonus.many"
                    large
                    label="Много"
                    placeholder="Введите название"
                    :error="validation('phrase.bonus.many').status"
                    :error-messages="validation('phrase.bonus.many').value"
                    data-test="config-phrase-bonus-many-input"
                    @input="v => handleBonusChange('many', v, 'phrase.bonus.many')"
                  />
                </VCol>
                <VCol cols="6">
                  <TTTextField
                    :value="entity.phrase.bonus.two"
                    large
                    label="Два"
                    placeholder="Введите название"
                    :error="validation('phrase.bonus.two').status"
                    :error-messages="validation('phrase.bonus.two').value"
                    data-test="config-phrase-bonus-two-input"
                    @input="v => handleBonusChange('two', v, 'phrase.bonus.two')"
                  />
                </VCol>
                <VCol cols="6">
                  <TTTextField
                    :value="entity.phrase.bonus.plural"
                    large
                    label="Множественное число"
                    placeholder="Введите название"
                    :error="validation('phrase.bonus.plural').status"
                    :error-messages="validation('phrase.bonus.plural').value"
                    data-test="config-phrase-bonus-plural-input"
                    @input="v => handleBonusChange('plural', v, 'phrase.bonus.plural')"
                  />
                </VCol>
                <VCol cols="6" />
              </VRow>
            </VForm>
            <VRow>
              <VCol
                align="left"
                class="mt-3"
              >
                <TTBtn
                  large
                  color="tt-secondary"
                  depressed
                  class="mr-6"
                  data-test="button-cancel"
                  @click="handleClose"
                >
                  Отменить
                </TTBtn>
                <TTBtn
                  large
                  :disabled="saving"
                  :loading="saving"
                  depressed
                  class="mr-6"
                  data-test="button-save"
                  @click="handleSave"
                >
                  Сохранить
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="modal-button-close"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { handleError, validation, watchValidationStatus } from '@/services';
import { compareColor, darkenColor, lightenColor } from '@/utils/colorUtils';

import UploadAvatar from '@/components/ui/UploadAvatar.vue';
import SColorField from '@/components/ui/SColorField.vue';
import CtxTimePicker from '@/components/ui/CtxTimePicker.vue';

export default {
  name: 'ConfigForm',
  components: {
    UploadAvatar,
    SColorField,
    CtxTimePicker,
  },
  props: {
    general: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      saving: false,
      validationStatus: [],
      colorRadios: 'light',
      entity: {},
      checkpoints: [
        {
          text: 'Без интеграции',
          value: 'NOT_SET',
        },
        {
          text: 'Календарь Google',
          value: 'GOOGLE',
        },
        {
          text: 'Оповещение через email',
          value: 'EMAIL',
        },
      ],
    };
  },
  computed: {
    showForm() {
      return Object.keys(this.entity).length;
    },
    primary() {
      return this.entity.style && this.entity.style.primary;
    },
    validPrimaryColor() {
      const colorRegex = /^#[a-fA-f0-9]{6}$/;
      return colorRegex.test(this.entity.style && this.entity.style.primary);
    },
    lightenColor() {
      if (this.validPrimaryColor) {
        return lightenColor(this.primary, 20);
      }
      return '';
    },
    darkenColor() {
      if (this.validPrimaryColor) {
        return darkenColor(this.primary, 20);
      }
      return '';
    },
    showRating: {
      get() {
        return !this.entity.hide_rating;
      },
      set(v) {
        this.entity.hide_rating = !v;
      },
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  created() {
    this.entity = cloneDeep(this.general);
    if (this.entity.style && this.entity.style.primaryActive) {
      if (!compareColor(this.entity.style.primaryActive, this.lightenColor)) {
        this.colorRadios = 'light';
      } else {
        this.colorRadios = 'dark';
      }
    }
  },
  methods: {
    validation,
    handleError,
    handleClose() {
      this.$emit('close');
    },
    handleChange(name, value, target) {
      this.entity[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleCheckpointChange(name, value, target) {
      this.entity.checkpoint[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleStyleChange(name, value, target) {
      this.entity.style[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleBonusChange(name, value, target) {
      this.entity.phrase.bonus[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleSave() {
      this.$refs.form.validate();
      if (this.valid) {
        this.saving = true;
        const general = { ...this.entity };
        general.scheme = '';
        if (this.colorRadios === 'light') {
          general.style.primaryActive = this.lightenColor;
        } else if (this.colorRadios === 'dark') {
          general.style.primaryActive = this.darkenColor;
        }
        this.$repositories.customer.updateConfig({
          data: {
            ...general,
          },
        })
          .then(() => {
            this.$emit('save');
          })
          .catch((e) => this.handleError(e))
          .finally(() => {
            this.saving = false;
          });
      }
    },
    setDefaultColor(target, hex) {
      this.entity.style[target] = hex;
    },
  },
};
</script>

<style lang="scss" scoped>
.undo-button {
  background: #EBECED;
  border: none;
}

.colored-box {
  height: 44px;
  width: 44px;
  border-radius: 4px;
  border: 1px solid #EBECED;
}
</style>

export const strToRGB = (str = '') => {
  const color = str.replace('#', '');
  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);
  return { r, g, b };
};

export const RGBToStr = ({ r, g, b }) => {
  const rStr = r > 0xf ? r.toString(16) : 0 + r.toString(16);
  const gStr = g > 0xf ? g.toString(16) : 0 + g.toString(16);
  const bStr = b > 0xf ? b.toString(16) : 0 + b.toString(16);
  return `#${rStr}${gStr}${bStr}`;
};

export const RGBToHSV = ({ r, g, b }) => {
  const normalizedR = r / 255;
  const normalizedG = g / 255;
  const normalizedB = b / 255;
  // Определение светлоты
  const v = Math.max(normalizedR, normalizedG, normalizedB);
  const minRGB = Math.min(normalizedR, normalizedG, normalizedB);
  // Определение насыщенности
  let s;
  if (v !== 0) {
    s = (v - minRGB) / v;
  } else {
    s = 0;
  }
  // Определение цветового тона
  let h;
  if (s >= 0.00001) {
    const cRed = (v - normalizedR) / (v - minRGB);
    const cGreen = (v - normalizedG) / (v - minRGB);
    const cBlue = (v - normalizedB) / (v - minRGB);
    if (normalizedR === v) {
      h = cBlue - cGreen;
    } else if (normalizedG === v) {
      h = 2 + cRed - cBlue;
    } else {
      h = 4 + cGreen - cRed;
    }
  } else {
    h = 0;
  }
  h *= 60;
  if (h < 0) {
    h += 360;
  }
  return { h, s, v };
};

const toHex = (float) => Math.round(float * 255);

export const HSVtoRGB = ({ h, s, v }) => {
  if (s <= 0) {
    return {
      r: toHex(v),
      g: toHex(v),
      b: toHex(v),
    };
  }
  let hue = (h < 360.0) ? h : 0.0;
  hue /= 60;
  const intHue = Math.floor(hue);
  const diff = hue - intHue;
  const p = v * (1.0 - s);
  const q = v * (1.0 - s * diff);
  const t = v * (1.0 - s * (1.0 - diff));
  switch (intHue) {
    case 0: return {
      r: toHex(v),
      g: toHex(t),
      b: toHex(p),
    };
    case 1: return {
      r: toHex(q),
      g: toHex(v),
      b: toHex(p),
    };
    case 2: return {
      r: toHex(p),
      g: toHex(v),
      b: toHex(t),
    };
    case 3: return {
      r: toHex(p),
      g: toHex(q),
      b: toHex(v),
    };
    case 4: return {
      r: toHex(t),
      g: toHex(p),
      b: toHex(v),
    };
    case 5:
    default: return {
      r: toHex(v),
      g: toHex(p),
      b: toHex(q),
    };
  }
};

export const darkenColor = (baseColor, percent) => {
  const normalizedPercent = percent / 100;
  const hsv = RGBToHSV(strToRGB(baseColor));
  hsv.v -= normalizedPercent;
  return RGBToStr(HSVtoRGB(hsv));
};

export const lightenColor = (baseColor, percent) => {
  const normalizedPercent = percent / 100;
  const hsv = RGBToHSV(strToRGB(baseColor));
  hsv.s -= normalizedPercent;
  return RGBToStr(HSVtoRGB(hsv));
};

export const compareColor = (str1, str2) => {
  const color1 = str1.replace('#', '');
  const color2 = str2.replace('#', '');
  return parseInt(color1, 16) - parseInt(color2, 16);
};

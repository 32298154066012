<template>
  <div>
    <VAvatar
      :size="size"
      :class="{'cursor--pointer' : clickable && img}"
    >
      <VImg
        :src="img || require('@/assets/images/nouser.png')"
        alt="avatar"
        @click="showBigAvatar"
      />
    </VAvatar>
    <DialogWrapper
      v-model="avatarDialog"
      max-width="800"
    >
      <VImg
        :lazy-src="img"
        :src="img || require('@/assets/images/nouser.png')"
        aspect-ratio="1.33333"
      >
        <template #placeholder>
          <VRow
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <VProgressCircular
              indeterminate
              color="primary"
            />
          </VRow>
        </template>
      </VImg>
    </DialogWrapper>
  </div>
</template>

<script>

import DialogWrapper from '@/components/shared/DialogWrapper.vue';

export default {
  name: 'SAvatar',
  components: { DialogWrapper },
  props: {
    img: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 64,
    },
  },
  data: () => ({
    avatarDialog: false,
  }),
  methods: {
    showBigAvatar(evt) {
      if (this.clickable && this.img) {
        evt.stopPropagation();
        this.avatarDialog = true;
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>

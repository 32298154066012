<template>
  <VContainer
    fluid
    class="config"
  >
    <DialogWrapper
      v-model="politicsForm"
      fullscreen
      @click:outside="closeModal('politicsForm')"
    >
      <ConfigPoliticsForm
        v-if="politicsForm"
        @close="closeModal('politicsForm')"
        @save="closeModal('politicsForm')"
      />
    </DialogWrapper>
    <DialogWrapper
      v-model="configForm"
      fullscreen
      @click:outside="closeModal('configForm')"
    >
      <ConfigForm
        v-if="configForm"
        :general="general"
        @close="closeModal('configForm')"
        @save="handleSave"
      />
    </DialogWrapper>

    <VRow>
      <VCol
        cols="6"
      >
        <h1 class="mb-5 tt-text-headline-1">
          Настройки
        </h1>
      </VCol>
    </VRow>
    <VRow v-if="!loading">
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Двухфакторная авторизация
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-two-factor-auth"
      >
        <h4 class="tt-text-body-2">
          {{ general.two_factor_auth ? 'Включена' : 'Выключена' }}
        </h4>
      </VCol>

      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Приветственное сообщение
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-sms-text"
      >
        <h4 class="tt-text-body-2">
          {{ general.sms_text }}
        </h4>
      </VCol>
      <VCol cols="10">
        <VDivider class="mt-3 mb-6" />
        <h3 class="tt-text-subtitle">
          Встречи
        </h3>
      </VCol>

      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Интеграция встреч
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-checkpoint-provider"
      >
        <h4 class="tt-text-body-2">
          {{ checkpoints[general.checkpoint.provider] }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Напоминания о встрече через приложение
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-checkpoint-is-active"
      >
        <h4 class="tt-text-body-2">
          {{ general.checkpoint.is_active ? 'Включена' : 'Выключена' }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Время напоминания по умолчанию
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-checkpoint-default-time"
      >
        <h4 class="tt-text-body-2">
          {{ general.checkpoint.default_time ? general.checkpoint.default_time : '9:00' }}
        </h4>
      </VCol>

      <VCol cols="10">
        <VDivider class="mt-3 mb-6" />
        <h3 class="tt-text-subtitle">
          Настройки приложения
        </h3>
      </VCol>

      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Запрос email через приложение
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-request-email"
      >
        <h4 class="tt-text-body-2">
          {{ general.request_email ? 'Включен' : 'Выключен' }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Блок «Цели на испытательный срок»
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-checkpoint-show-purposes"
      >
        <h4 class="tt-text-body-2">
          {{ general.show_purposes ? 'Отображается' : 'Не отображается' }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Наставничество
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-checkpoint-show-purposes"
      >
        <h4 class="tt-text-body-2">
          {{ general.use_tutorship ? 'Включено' : 'Выключено' }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Вкладка «Рейтинг» на пребординге
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-checkpoint-show-rating"
      >
        <h4 class="tt-text-body-2">
          {{ !general.hide_rating ? 'Отображается' : 'Не отображается' }}
        </h4>
      </VCol>
      <VCol
        cols="10"
        class="pb-0"
      >
        <VDivider class="mt-3 mb-6" />
        <h3 class="tt-text-subtitle">
          Бот
        </h3>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Аватар
        </h3>
      </VCol>
      <VCol
        cols="7"
        class="pt-0"
      >
        <SAvatar
          data-test="config-bot-icon"
          :img="general.bot_icon_url"
          :size="46"
        />
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Имя
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-bot-name"
      >
        <h4 class="tt-text-body-2">
          {{ general.bot_name }}
        </h4>
      </VCol>

      <VCol cols="10">
        <VDivider class="mt-3 mb-6" />
        <h3 class="tt-text-subtitle">
          Цветовая схема
        </h3>
      </VCol>

      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Основной цвет
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-style-primary"
      >
        <div class="d-flex flex-row justify-start align-center">
          <div
            class="colored-box mr-2"
            :style="`background-color: ${style.primary}`"
          />
          <h4 class="tt-text-body-2">
            {{ style.primary }}
          </h4>
        </div>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Цвет текста на кнопках
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-style-button"
      >
        <div class="d-flex flex-row justify-start align-center">
          <div
            class="colored-box mr-2"
            :style="`background-color: ${style.primaryText}`"
          />
          <h4 class="tt-text-body-2">
            {{ style.primaryText }}
          </h4>
        </div>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Цвет при нажатии
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-style-button-click"
      >
        <div class="d-flex flex-row justify-start align-center">
          <div
            class="colored-box mr-2"
            :style="`background-color: ${style.primaryActive}`"
          />
          <h4 class="tt-text-body-2">
            {{ isLighten ? 'Светлая палитра' : 'Темная палитра' }}
          </h4>
        </div>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Пример использования
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-style-example"
        class="pb-1"
      >
        <VCard
          width="376"
          class="pa-6"
          :style="`box-shadow: 0px 2px 24px rgba(11, 18, 24, 0.08);border-radius: 12px;`"
        >
          <VCardText
            :style="`background-color: ${style.primary}; border: 1px solid ${style.primary}`"
            class="text-center colored-box-example mb-5"
          >
            <h3
              :style="`color: ${style.primaryText};font-size: 14;font-weight: 500`"
            >
              Пример использования
            </h3>
          </VCardText>
          <VCardText
            :style="`background-color: ${style.primaryText}; border: 1px solid ${style.primary}`"
            class="text-center colored-box-example"
          >
            <h3
              :style="`color: ${style.primary};font-size: 14;font-weight: 500`"
            >
              Пример использования
            </h3>
          </VCardText>
        </VCard>
      </VCol>
      <VCol cols="10">
        <VDivider class="mt-3 mb-6" />
        <h3 class="tt-text-subtitle">
          Бонус
        </h3>
      </VCol>

      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Один
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-bonus-one"
      >
        <h4 class="tt-text-body-2">
          {{ bonus.one }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Два
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-bonus-two"
      >
        <h4 class="tt-text-body-2">
          {{ bonus.two }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Много
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-bonus-many"
      >
        <h4 class="tt-text-body-2">
          {{ bonus.many }}
        </h4>
      </VCol>
      <VCol
        cols="3"
        class="text-right"
      >
        <h3 class="tt-text-body-2 tt-light-mono-46--text">
          Множественное число
        </h3>
      </VCol>
      <VCol
        cols="7"
        data-test="config-bonus-plural"
      >
        <h4 class="tt-text-body-2">
          {{ bonus.plural }}
        </h4>
      </VCol>

      <VCol cols="10">
        <VDivider class="mt-3 mb-6" />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { compareColor, lightenColor } from '@/utils/colorUtils';

import ConfigForm from '@/components/forms/ConfigForm.vue';
import ConfigPoliticsForm from '@/components/forms/ConfigPoliticsForm.vue';
import SAvatar from '@/components/ui/SAvatar.vue';
import DialogWrapper from '@/components/shared/DialogWrapper.vue';
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'Config',
  components: {
    DialogWrapper,
    ConfigForm,
    ConfigPoliticsForm,
    SAvatar,
  },
  data() {
    return {
      configForm: false,
      politicsForm: false,
      general: {},
      loading: false,
      checkpoints: {
        NOT_SET: 'Без интеграции',
        GOOGLE: 'Календарь Google',
        EMAIL: 'Оповещение через email',
      },
    };
  },
  computed: {
    ...mapGetters('customerInfo', ['config']),
    style() {
      return this.general.style || {};
    },
    phrase() {
      return this.general.phrase || {};
    },
    bonus() {
      return this.phrase.bonus || {};
    },
    validPrimaryColor() {
      const colorRegex = /^#[a-fA-f0-9]{6}$/;
      return colorRegex.test(this.style.primary);
    },
    lightenColor() {
      if (this.validPrimaryColor) {
        return lightenColor(this.style.primary, 20);
      }
      return '';
    },
    isLighten() {
      return !compareColor(this.style.primaryActive, this.lightenColor);
    },
  },
  created() {
    if (!this.config) {
      this.getData();
    } else {
      this.cloneConfig();
    }
  },
  mounted() {
    this.$root.$on('showConfigForm', this.showConfigForm);
    this.$root.$on('showPoliticsForm', this.showPoliticsForm);
  },
  beforeDestroy() {
    this.$root.$off('showConfigForm', this.showConfigForm);
    this.$root.$off('showPoliticsForm', this.showPoliticsForm);
  },
  methods: {
    ...mapActions('customerInfo', ['getCustomerConfig']),
    showConfigForm() {
      this.openModal('configForm');
    },
    showPoliticsForm() {
      this.openModal('politicsForm');
    },
    closeModal(name) {
      this[name] = false;
    },
    openModal(name) {
      this[name] = true;
    },
    handleSave() {
      this.closeModal('configForm');
      this.getData();
    },
    cloneConfig() {
      this.general = cloneDeep(this.config);
    },
    async getData() {
      try {
        this.loading = true;
        await this.getCustomerConfig();
        this.cloneConfig();
        this.loading = false;
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style>
.config .row .col {
  padding-top: 8px;
  padding-bottom: 8px;
}

.colored-box {
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #EBECED;
}

.colored-box-example {
  border-radius: 5px !important;
}

</style>

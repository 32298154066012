<template>
  <VContainer
    fluid
    class="pa-6"
  >
    <VRow>
      <VCol cols="1" />
      <VCol cols="10">
        <VRow>
          <VCol
            lg="9"
            sm="12"
          >
            <VRow>
              <VCol
                cols="6"
              >
                <h1 class="tt-text-headline-1">
                  Политики
                </h1>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <TTTextField
                  :value="agreement.header"
                  large
                  label="Заголовок предупреждения об обработке персональных данных"
                  placeholder="Введите заголовок"
                  :error="validation('header').status"
                  :error-messages="validation('header').value"
                  data-test="politics-agreement-header-input"
                  @input="v => handleChange('header',v)"
                />
              </VCol>
              <VCol cols="12">
                <div class="mb-3">
                  Текст оферты на обработку персональных данных
                </div>
                <SEditor
                  ref="editor"
                  height="300"
                  data-test="politics-agreement-body"
                  @text-change="handleEditorChange"
                />
              </VCol>
              <VCol cols="12">
                <TTTextField
                  :value="agreement.attention"
                  large
                  label="Подтверждение обработки персональных данных"
                  placeholder="Введите текст подтверждения"
                  :error="validation('attention').status"
                  :error-messages="validation('attention').value"
                  data-test="politics-agreement-attention-input"
                  @input="v => handleChange('attention',v)"
                />
              </VCol>
            </VRow>
            <VRow>
              <VCol
                align="left"
                class="mt-3"
              >
                <TTBtn
                  color="tt-secondary"
                  large
                  depressed
                  class="mr-6"
                  data-test="button-cancel"
                  @click="handleClose"
                >
                  Отменить
                </TTBtn>
                <TTBtn
                  large
                  :disabled="saving"
                  :loading="saving"
                  depressed
                  class="mr-6"
                  data-test="button-save"
                  @click="handleSave"
                >
                  Сохранить
                </TTBtn>
              </VCol>
            </VRow>
          </VCol>
        </VRow>
      </VCol>
      <VCol
        cols="1"
        class="text-center"
      >
        <div class="aside-panel">
          <div class="aside-panel__wrapper">
            <TTBtn
              fab
              color="white"
              elevation="1"
              large
              class="mb-2"
              data-test="modal-button-cancel"
              @click="handleClose"
            >
              <VIcon
                color="tt-black"
                size="19"
              >
                fal fa-times
              </VIcon>
            </TTBtn>
            <div class="tt-text-caption tt-black--text text--lighten-2">
              Закрыть
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { handleError, validation, watchValidationStatus } from '@/services';

import SEditor from '@/components/editor/SEditor.vue';

export default {
  name: 'ConfigPoliticsForm',
  components: { SEditor },
  data() {
    return {
      isLoaded: false,
      saving: false,
      validationStatus: [],
      agreement: {
        body: '',
        header: '',
        attention: '',
      },
    };
  },
  computed: {
    editor() {
      return this.$refs.editor;
    },
  },
  watch: {
    validationStatus: watchValidationStatus,
    globalErrorMessage: watchValidationStatus,
  },
  async mounted() {
    try {
      const { data: { data } } = await this.$repositories.customer.getConfigPolitics({});
      this.agreement = data.agreement;
      this.editor.insertRawHtml(data.agreement.body);
      this.isLoaded = true;
    } catch (error) {
      console.warn(error);
    }
  },
  methods: {
    validation,
    handleError,
    handleClose() {
      this.$emit('close');
    },
    handleChange(name, value, target) {
      this.agreement[name] = value;
      const key = target || name;
      this.validationStatus = this.validationStatus.filter((i) => i.key !== key);
    },
    handleEditorChange() {
      this.agreement.body = this.editor.getHtml();
    },
    async handleSave() {
      try {
        this.saving = true;
        await this.$repositories.customer.updatePoliticsConfig({ data: { agreement: this.agreement } });
        this.$emit('save');
      } catch (e) {
        this.handleError(e);
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>

<template>
  <TTTextField
    large
    :value="value"
    :error-messages="errorMessagesAttr"
    v-bind="$attrs"
    autocomplete="off"
    @input="handleInput"
    @change="handleChange"
  />
</template>

<script>
export default {
  name: 'SColorField',
  props: {
    value: {
      type: String,
      required: true,
    },
    errorMessages: {
      type: [String, Array],
      default: () => ([]),
    },
  },
  data() {
    return {
      errorMessage: '',
      inputValue: '',
    };
  },
  computed: {
    listeners() {
      const { input, change, ...others } = this.$listeners;
      return { ...others };
    },
    errorMessagesAttr() {
      if (!this.errorMessage) return this.errorMessages;
      if (Array.isArray(this.errorMessages)) {
        return this.errorMessages[0]
          ? this.errorMessages.concat(this.errorMessage) : [this.errorMessage];
      }
      return this.errorMessages ? [this.errorMessages, this.errorMessage] : [this.errorMessage];
    },
  },
  methods: {
    validate(val) {
      const colorRegex = /^#[a-fA-f0-9]{6}$/;
      return colorRegex.test(val);
    },
    handleInput(val) {
      this.errorMessage = '';
      this.$emit('input', val);
    },
    handleChange(val) {
      if (!this.validate(val)) {
        this.errorMessage = 'Введите HEX цвет в формате #0025FF';
      }
      this.$emit('change', val);
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
